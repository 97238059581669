<template>
  <div class="fill-height">
    <div ref="beforeTable" class="d-flex text-h5 font-weight-medium pa-4">
    <!-- style="color: #2c3c56" -->
      Processamento
      <v-text-field
        color="grey"
        outlined
        :class="{
          'ml-3': !$vuetify.breakpoint.mobile,
          'flex-grow-1': true,
        }"
        dense
        hide-details="auto"
        v-model="search"
        label="Pesquisar"
        placeholder="Pesquisar por Nome ou CPF do cliente"
        prepend-inner-icon="mdi-magnify"
        clearable
      />
      <div style="width: 150px">
        <v-select
          outlined
          dense
          hide-details="auto"
          color="grey"
          item-color="blue darken-2"
          label="Ano Exercício"
          :items="years"
          v-model="selectedYear"
          class="mx-1"
        />
      </div>
      <div style="width: 300px">
        <v-select
          outlined dense
          color="grey" item-color="blue darken-2"
          hide-details="auto"
          label="Filtrar por Status"
          v-model="currentStatusFilter"
          :items="statusFilters"
          item-text="text"
          item-value="value"
        />
      </div>
    </div>
    <v-divider />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="clients"
      fixed-header
      hide-default-footer
      disable-pagination
      must-sort
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :custom-sort="updateSort"
      loading-text="Carregando... Por favor, aguarde"
      :height="tableHeight"
    >
      <template #no-data>
        <div v-if="tryAgain" class="my-4 black--text">
          <div>
            Erro ao carregar a lista de clientes
          </div>
          <v-btn class="my-3" color="error" small depressed @click="tryAgain = false">
            tentar novamente
          </v-btn>
        </div>
        <div v-else-if="loading" />
        <div v-else>
          Nenhum cliente encontrado
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        {{
          item.latest_update == null ? "Ainda não consultado" :
          statusToText(item.latest_update.status)
        }}
      </template>
      <template v-slot:[`item.last_update`]="{ item }">
        {{
          item.latest_update == null ? "-" :
          $utils.formatDate(item.latest_update.last_change)
        }}
      </template>
      <template v-slot:[`item.consulted_on`]="{ item }">
        {{
          item.latest_update == null ? "-" :
          $utils.formatDateTime(item.latest_update.consulted_on)
        }}
      </template>

       <template #[`body.append`]>
        <tr v-if="!fullyLoaded && !tryAgain && started">
          <td :colspan="headers.length">
            <scroll-loader
              :loader-method="loadClients"
              :loader-disable="fullyLoaded"
            >
              <v-progress-circular
                indeterminate
                :size="20"
                :width="2"
              />
            </scroll-loader>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
const processingStatusTranslations = {
  not_consulted: "Ainda não consultado",
  wrong_birth_date: "Data de nascimento errada",
  not_in_base: "Não consta na base da receita",
  // pending: "Pendente",
  processing: "Em Processamento",
  processing_medium_time: "Em Processamento há 2 dias ou mais",
  // processing_long_time: "Em Processamento há 7 dias ou mais",
  restitution_waiting: "Restituição na fila",
  restitution_sent_credit: "Restituição disponível no banco",
  restitution_credited: "Restituição creditada na conta",
  restitution_waiting_reschedule: "Restituição aguardando reagendamento",
  processed_with_taxes: "Processado com imposto a pagar",
  no_taxes_nor_restitution: "Processado sem imposto nem restituição",
  // fiscal_issues: "Problemas de malha",
  // unknown: "Desconhecido",
  consult_ecac: "Risco de Malha, Consultar E-CAC",
};

const currentYear = new Date().getFullYear();

export default {
  name: "ProcessingStatus",

  props: ["contentHeight"],

  data: () => ({
    started: false,
    loading: false,
    tryAgain: false,
    filtersMenu: false,

    changeTimeout: undefined,
    fullyLoaded: false,

    limit: 30,
    selectedYear: currentYear,
    search: null,
    currentStatusFilter: null,
    sortBy: 'name',
    sortDesc: false,

    statusFilters: [
      {text: "Todos", value: null},
      ...Object.entries(processingStatusTranslations).map((entry) => ({
        value: entry[0],
        text: entry[1],
      })),
    ],
    headers: [
      { text: "Nome", value: 'name' },
      { text: "CPF", value: "cpf" },
      { text: "Status", value: "status" },
      { text: "Última Alteração", value: "last_update" },
      { text: "Última Consulta", value: "consulted_on" },
    ],
    clients: [],
    spaceBeforeTable: 73,
  }),

  computed: {
    years() {
      let years = [];
      for (let year = currentYear; year >= 2017; --year){
        years.push(year);
      }
      return years;
    },
    tableHeight() {
      return (this.contentHeight -1- this.spaceBeforeTable) + "px";
    },
  },

  watch: {
    search() {this.resetLoader();},
    selectedYear() {this.resetLoader();},
    currentStatusFilter() {this.resetLoader();},
    desc() {this.resetLoader();},
    filterField() {this.resetLoader();}
  },

  methods: {
    resetLoader(){
      this.started = true;
      if (this.changeTimeout != undefined){
        clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = setTimeout(() => {
        if (this.loading){
          this.resetLoader();
        } else {
          this.fullyLoaded = false;
          this.$set(this, 'clients', []);
        }
      }, 300);
    },
    statusToText(status) {
      return processingStatusTranslations[status] || "-";
    },
    newCustomer() {
      this.$axios
        .post("/irpf/client", this.client)
        .then(() => {
          this.$showMessage("success", "Cliente Salvo");
          this.$emit("updated");
          this.dialog = false;
        })
        .catch((error) => {
          this.$showMessage("error", "Erro inesperado ao salvar o cliente");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSort(items, sortBy, sortDesc){
      sortBy = sortBy[0];
      sortDesc = sortDesc[0];
      if (this.sortBy != sortBy || this.sortDesc != sortDesc){
        this.sortBy = sortBy;
        this.sortDesc = sortDesc;
        this.resetLoader();
      }
      return items;
    },
    reload(){
      if (this.started){
        this.resetLoader();
      } else {
        this.started = true;
      }
    },
    loadClients() {
      this.loading = true;
      this.tryAgain = false;
      this.$axios
        .patch(`/irpf/clients`, {
          year: this.selectedYear,
          search_text: this.search,
          status: this.currentStatusFilter,
          order_by_field: this.sortBy,
          order_by_desc: this.sortDesc,
        },
        {
          params: {
            limit: this.limit,
            offset: this.clients.length,
          },
        })
        .then((response) => {
          this.clients = [...this.clients, ...response.data];    
          if (response.data.length < this.limit)
          {
            this.fullyLoaded = true;
          }
        })
        .catch((error) => {
          this.tryAgain = true;
          this.$showMessage("warning", "Erro ao carregar os clientes");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.spaceBeforeTable = this.$refs.beforeTable.clientHeight;
    });
  },
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(44, 60, 86, 0.08);
}
</style>
