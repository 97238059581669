var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('div',{ref:"beforeTable",staticClass:"d-flex text-h5 font-weight-medium pa-4"},[_vm._v(" Processamento "),_c('v-text-field',{class:{
        'ml-3': !_vm.$vuetify.breakpoint.mobile,
        'flex-grow-1': true,
      },attrs:{"color":"grey","outlined":"","dense":"","hide-details":"auto","label":"Pesquisar","placeholder":"Pesquisar por Nome ou CPF do cliente","prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticStyle:{"width":"150px"}},[_c('v-select',{staticClass:"mx-1",attrs:{"outlined":"","dense":"","hide-details":"auto","color":"grey","item-color":"blue darken-2","label":"Ano Exercício","items":_vm.years},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1),_c('div',{staticStyle:{"width":"300px"}},[_c('v-select',{attrs:{"outlined":"","dense":"","color":"grey","item-color":"blue darken-2","hide-details":"auto","label":"Filtrar por Status","items":_vm.statusFilters,"item-text":"text","item-value":"value"},model:{value:(_vm.currentStatusFilter),callback:function ($$v) {_vm.currentStatusFilter=$$v},expression:"currentStatusFilter"}})],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.clients,"fixed-header":"","hide-default-footer":"","disable-pagination":"","must-sort":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-sort":_vm.updateSort,"loading-text":"Carregando... Por favor, aguarde","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.tryAgain)?_c('div',{staticClass:"my-4 black--text"},[_c('div',[_vm._v(" Erro ao carregar a lista de clientes ")]),_c('v-btn',{staticClass:"my-3",attrs:{"color":"error","small":"","depressed":""},on:{"click":function($event){_vm.tryAgain = false}}},[_vm._v(" tentar novamente ")])],1):(_vm.loading)?_c('div'):_c('div',[_vm._v(" Nenhum cliente encontrado ")])]},proxy:true},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.latest_update == null ? "Ainda não consultado" : _vm.statusToText(item.latest_update.status))+" ")]}},{key:"item.last_update",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.latest_update == null ? "-" : _vm.$utils.formatDate(item.latest_update.last_change))+" ")]}},{key:"item.consulted_on",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.latest_update == null ? "-" : _vm.$utils.formatDateTime(item.latest_update.consulted_on))+" ")]}},{key:"body.append",fn:function(){return [(!_vm.fullyLoaded && !_vm.tryAgain && _vm.started)?_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('scroll-loader',{attrs:{"loader-method":_vm.loadClients,"loader-disable":_vm.fullyLoaded}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"width":2}})],1)],1)]):_vm._e()]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }